<template>
  <div class="px-4 md:px-16">
    <section class="md:max-w-[1560px] mx-auto">
      <div
        class="bg-white py-10 flex w-full items-center flex-col rounded-t-lg lg:py-12"
      >
        <div
          class="bg-white shadow-icone rounded-full w-[70px] h-[70px] flex justify-center items-center mb-8 lg:w-[135px] lg:h-[135px]"
        >
          <inline-svg
            class="mr-1 w-8 lg:w-16"
            :src="require('@/assets/novo/discussion.svg')"
            title="Orientações"
          />
        </div>
        <div
          class="text-center px-8 text-[#727272] lg:text-lg lg:px-20 xl:px-48"
        >
          <h1 class="text-[#003D42] font-bold text-xl mb-6 uppercase">
            Orientações
          </h1>
          <p class="mb-3">
            O programa <strong class="text-[#45AEB1]">RAStrear</strong> preza
            pela qualidade de seus serviços e segue rigorosamente as regras e
            legislações vigentes, por isso é importante que todos os documentos
            solicitados estejam armazenados junto da amostra e dentro da caixa
            do programa <strong class="text-[#45AEB1]">RAStrear</strong>.
          </p>
          <p class="mb-3">
            A ausência desses documentos acarretará em demora no prazo de
            análise ou até mesmo em seu cancelamento.
          </p>
          <p class="mb-3 text-red-500">Fique atento.</p>
        </div>
      </div>
    </section>
    <div class="mb-16">
      <section
        class="px-6 py-8 flex flex-col justify-center items-center bg-[#0D595D] md:max-w-[1560px] md:px-8 mx-auto lg:px-10 lg:text-lg"
      >
        <div
          class="bg-white shadow-icone rounded-full w-[70px] h-[70px] flex justify-center items-center mb-4 lg:w-[135px] lg:h-[135px]"
        >
          <inline-svg
            class="mr-1 w-8 lg:w-14"
            :src="require('@/assets/novo/processo.svg')"
            title="Processo"
          />
        </div>
        <h2 class="text-white font-bold text-xl mb-8 uppercase">
          PROCESSO DE SOLICITAÇÃO
        </h2>
        <inline-svg
          class="lg:hidden"
          :src="require('@/assets/novo/passo-a-passo-vertical.svg')"
          title="Passo a passo"
        />
        <inline-svg
          class="hidden lg:block w-3/4"
          :src="require('@/assets/process.svg')"
          title="Passo a passo"
        />
      </section>
      <section
        class="px-4 py-8 pb-16 bg-white rounded-b-lg md:max-w-[1560px] md:px-8 mx-auto lg:px-10 lg:text-lg lg:py-12"
      >
        <div class="text-[#727272] flex flex-col items-center">
          <h2
            class="text-[#003D42] font-bold text-lg leading-tight mb-10 uppercase text-center"
          >
            PARA FAZER A <br />
            SOLICITAÇÃO <br />
            DO TESTE RAS
          </h2>
        </div>
        <ul
          class="flex flex-wrap content-center text-center justify-center text-base mt-2"
        >
          <MBox
            data-count="1"
            class="py-16 px-2 mb-10 mx-2 w-full md:w-2/5 md:ml-0 lg:w-auto lg:flex-1 lg:mb-0 relative before:absolute before:text-white before:text-center before:text-4xl before:rounded-full before:p-2 before:h-16 before:w-16 before:font-bold before:shadow-lg"
            wrapper="li"
          >
            <inline-svg
              aria-label="Página O Programa"
              class="inline-block w-24 mb-5"
              :src="require('@/assets/display.svg')"
              title="Página O Programa"
            />
            <div class="m-auto w-36 text-[#244170]">
              <a
                href="#"
                title="Página Solicitação de exames"
                class="text-secondary font-bold underline text-xl tracking-wide"
                @click.prevent="onClick"
              >
                Clique aqui <br />
              </a>
              para fazer login e faça sua solicitação
            </div>
          </MBox>
          <MBox
            data-count="2"
            class="py-16 px-2 mb-10 mx-2 w-full md:w-2/5 md:mr-0 lg:w-auto lg:flex-1 lg:mx-2 lg:mb-0 relative before:absolute before:text-white before:text-center before:text-4xl before:rounded-full before:p-2 before:h-16 before:w-16 before:font-bold before:shadow-lg"
            wrapper="li"
          >
            <inline-svg
              aria-label="Preencha o formulário"
              class="inline-block w-20 mb-3 translate-x-4"
              :src="require('@/assets/form-fill.svg')"
              title="Preencha o formulário"
            />
            <div class="m-auto w-36 text-[#244170]">
              Preencha <br />
              <strong class="text-secondary">todos os dados</strong> <br />
              nos campos da <br />solicitação
            </div>
          </MBox>
          <MBox
            data-count="3"
            class="py-16 px-2 mb-10 mx-2 w-full md:w-2/5 md:ml-0 lg:w-auto lg:flex-1 lg:mx-2 lg:mb-0 relative before:absolute before:text-white before:text-center before:text-4xl before:rounded-full before:p-2 before:h-16 before:w-16 before:font-bold before:shadow-lg"
            wrapper="li"
          >
            <inline-svg
              aria-label="Faça a impressão dos termos"
              class="inline-block w-20 mb-3"
              :src="require('@/assets/printer.svg')"
              title="Faça a impressão dos termos"
            />
            <div class="m-auto w-48 text-[#244170]">
              Faça a impressão dos termos que serão mostrados no término da
              solicitação.
              <strong class="text-secondary"
                >Não esqueça de coletar as assinaturas nestes documentos</strong
              >
            </div>
          </MBox>
          <MBox
            data-count="4"
            class="py-16 px-2 mb-10 mx-2 w-full md:w-2/5 md:mr-0 lg:w-auto lg:flex-1 lg:mb-0 relative before:absolute before:text-white before:text-center before:text-4xl before:rounded-full before:p-2 before:h-16 before:w-16 before:font-bold before:shadow-lg"
            wrapper="li"
          >
            <inline-svg
              aria-label="Impressão"
              class="inline-block w-20 mb-3"
              :src="require('@/assets/novo/storage-box.svg')"
              title="Impressão"
            />
            <div class="m-auto w-50 text-[#244170]">
              Acomode os documentos e <br />a amostra na caixa do RAStrear
              <br />
              <strong class="text-secondary">conforme passos a seguir</strong>
            </div>
          </MBox>
        </ul>
      </section>
      <section
        class="px-6 py-8 pb-2 flex flex-col justify-center items-center bg-[#0D595D] md:max-w-[1560px] md:px-8 mx-auto lg:px-10 lg:text-lg amostra"
      >
        <div
          class="bg-white shadow-icone rounded-full w-[70px] h-[70px] flex justify-center items-center mb-4 lg:w-[135px] lg:h-[135px]"
        >
          <inline-svg
            class="mr-1 w-8 lg:w-16 translate-x-1"
            :src="require('@/assets/novo/prancheta2.svg')"
            title="Prancheta"
          />
        </div>
        <h1 class="text-white font-bold text-sm mb-8 uppercase">
          COMO PREPARAR A CAIXA
        </h1>
        <ul
          class="flex flex-wrap content-center text-center justify-center text-base mt-12 mb-12 md:px-2 xl:text-lg xl:px-20"
        >
          <MBox
            data-count="1"
            class="min-h-[108px] flex items-center px-8 py-7 mb-10 mx-2 w-full text-primary md:w-auto md:mb-0 md:flex-1 xl:w-1/4 xl:flex-auto relative before:absolute before:text-white before:text-center before:text-lg before:rounded-full before:p-2 before:h-11 before:w-11 before:font-bold before:shadow-xl"
            wrapper="li"
          >
            <div class="m-auto leading-tight text-sm">
              <strong>Preencha</strong> todos os dados que são solicitados na
              frente (tampa) da caixa recebida;
            </div>
          </MBox>
          <MBox
            data-count="2"
            class="min-h-[108px] flex items-center px-8 py-7 mb-10 mx-2 w-full text-primary md:w-auto md:mb-0 md:flex-1 xl:w-1/4 xl:flex-auto relative before:absolute before:text-white before:text-center before:text-lg before:rounded-full before:p-2 before:h-11 before:w-11 before:font-bold before:shadow-xl"
            wrapper="li"
          >
            <div class="m-auto text-sm leading-tight">
              <strong>Acondicione</strong>
              a amostra dentro do plástico fornecido;
            </div>
          </MBox>
          <MBox
            data-count="3"
            class="min-h-[108px] flex items-center px-8 py-7 mb-10 mx-2 w-full text-primary md:w-auto md:mb-0 md:flex-1 xl:w-1/4 xl:flex-auto relative before:absolute before:text-white before:text-center before:text-lg before:rounded-full before:p-2 before:h-11 before:w-11 before:font-bold before:shadow-xl"
            wrapper="li"
          >
            <div class="m-auto text-sm leading-tight">
              <strong>Acondicione</strong> o pote plástico com a amostra dentro
              da caixa recebida;
            </div>
          </MBox>
          <MBox
            data-count="4"
            class="min-h-[108px] flex items-center flex-col py-7 px-2 mb-10 mx-2 w-full text-primary md:w-auto md:mb-0 md:flex-auto md:mt-12 lg:px-4 xl:pb-8 relative before:absolute before:text-white before:text-center before:text-lg before:rounded-full before:p-2 before:h-11 before:w-11 before:font-bold before:shadow-xl"
            wrapper="li"
          >
            <div class="m-auto text-sm leading-tight">
              <strong>Coloque</strong> todos os documentos com suas respectivas
              assinaturas coletadas dentro da caixa recebida. Os termos são:
            </div>
            <ul
              class="text-sm mt-2 md:flex md:flex-wrap content-center text-center justify-center"
            >
              <li
                class="min-h-[78px] flex justify-center flex-col rounded-lg border border-solid border-primary px-3 py-3 leading-tight md:ml-0 md:mr-2 md:flex-1 md:py-6"
              >
                <div>
                  <strong>Termos de Consentimento</strong>: <br />
                  Este termo é assinado pelo paciente;
                </div>
              </li>
              <li
                class="min-h-[78px] flex justify-center flex-col rounded-lg border border-solid border-primary px-3 py-3 leading-tight md:mr-2 md:ml-2 md:flex-1 md:py-6"
              >
                <div>
                  <strong>Declaração de Transporte</strong>: <br />
                  Essa declaração é assinada pelo médico solicitante;
                </div>
              </li>
              <li
                class="min-h-[78px] flex justify-center flex-col rounded-lg border border-solid border-primary px-3 py-3 leading-tight md:mr-2 md:ml-2 md:flex-1 md:py-6"
              >
                <div>
                  <strong>Termo de emergência</strong>: <br />
                  Esse termo não precisa ter assinaturas;
                </div>
              </li>
              <li
                class="min-h-[78px] flex justify-center flex-col rounded-lg border border-solid border-primary px-3 py-3 leading-tight md:mr-0 md:flex-1 md:py-6 md:mt-4 lg:ml-2 lg:mt-0"
              >
                <div>
                  <strong>Laudo Anátomopatológico</strong>: <br />
                  É obrigatório a apresentação deste laudo.
                </div>
              </li>
            </ul>
          </MBox>
          <MBox
            data-count="5"
            class="items-center flex py-10 px-6 mb-10 mx-2 w-full text-primary md:w-2/5 md:mb-0 md:flex-auto md:mt-12 lg:px-4 xl:w-1/3 relative before:absolute before:text-white before:text-center before:text-lg before:rounded-full before:p-2 before:h-11 before:w-11 before:font-bold before:shadow-xl"
            wrapper="li"
          >
            <div class="m-auto text-sm leading-tight">
              <img
                class="w-20 m-auto mb-4"
                src="@/assets/truck.png"
                alt="Caminhão"
              />
              <strong>Pronto</strong>, a caixa está pronta e agora é
              <strong>só aguardar a coleta</strong>.
            </div>
          </MBox>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import MBox from "@/components/molecules/Box.vue";
import useGetLink from "@/composables/useGetLink";
import useRedirect from "@/composables/useRedirect";
import { inject } from "vue";

export default {
  name: "Orientacoes",
  components: {
    MBox,
  },
  setup() {
    const redirect = inject("redirect");
    const { link } = useGetLink({ path: "colorretal" });

    return {
      onClick: () => useRedirect({ link, redirect }),
    };
  },
};
</script>

<style lang="postcss">
.o-boxes__box {
  &::before {
    background: rgb(112, 210, 213);
    background: radial-gradient(
      circle,
      rgba(112, 210, 213, 1) 0%,
      rgba(69, 174, 177, 1) 100%
    );
    content: attr(data-count);
    left: 50%;
    top: -1.4rem;
    transform: translateX(-50%);
  }
}

.amostra {
  .o-boxes__box {
    &::before {
      background: rgb(47, 104, 149);
      background: radial-gradient(
        circle,
        rgba(47, 104, 149, 1) 0%,
        rgba(27, 70, 104, 1) 100%
      );
    }
  }
}
</style>
